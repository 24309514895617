* {
  padding: 0;
  margin: 0;
}

@charset "utf-8";
/* CSS Document */

@font-face {
	font-family: "Comfortaa";
	src:  url(Comfortaa-Light.ttf) format("truetype");
}

body {
	font-family: "Comfortaa";
}

a, a:hover {
  text-decoration: none;
  color: inherit;
}

#nav-admin {
  background-color: #a73972;
  padding: 10px;
  color: white;
  font-size: 16px;
}

#nav-admin .nav-desktop {
  width: 90%;
  float: right;
}

#nav-admin .nav-desktop li {
  width: 120px;
  display: inline-block;
}

#nav-admin .nav-mobile {
  margin-top: 20px;
  position: absolute;
  background-color: #a73972;
  width: 100%;
  padding: 0;
  margin-left: -10px;
  top: 40px;
  z-index: 999;
}

#nav-admin .nav-mobile li {
  width: 90%;
  display: block;
  margin: 0 auto;
}

#nav-admin ul li {
  text-align: center;
  list-style: none;
  margin: 0 10px;
  padding: 10px 0;
  cursor: pointer
}

#nav-admin ul li:hover {
  background-color: #d77eac
}

#nav-admin ul li.active {
  background-color: #d77eac
}

#nav-admin .navbar-toggler {
  cursor: pointer;
	border: 0 !important;
	background-color: #a73972 !important;
}

#nav-admin .content-nav-brand {
  width: 70px;
  float: right;
}

#nav-admin .bar1, #nav-admin .bar2, #nav-admin .bar3 {
  width: 35px;
  height: 5px;
  background-color: rgba(215, 126, 172, 0.6);
  margin: 6px 0;
  transition: 0.4s;
}

#nav-admin .change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background-color: rgba(215, 126, 172, 1);
}

#nav-admin .change .bar2 {opacity: 0;}

#nav-admin .change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: rgba(215, 126, 172, 1);
}

#nav-admin nav.open {
	position: fixed !important;
	height: 100vh;
  overflow: auto;
  padding-bottom: 70px;
}

#footer {
  background-color: #a73972;
}

#login {
  padding-top: 50px;
  color: white;
  background-color: #a73972;
}

#login label {
  width: 100%;
}

#login #error-login {
  display: none;
}

/* HOME */

.single-user {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.user-id {
  float: right;
  font-size: 22px;
}

.list-update {
  width: 95%;
  text-align: center;
  background-color: #d77eac;
  color: white;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50px;
  -webkit-box-shadow: 0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px -2px 15px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px -2px 15px 0px rgba(50, 50, 50, 0.75);
}

/* SEND EMAILS */

.single-user.send-emails {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.btn-submit {
	background-color: #d77eac;
	border: 2px solid white;
	color: white;
	padding: 10px;
	border-radius: 6px;
	width: 100%;
	margin: 0 auto;
  transition: all .2s ease-in-out;
}

.btn-submit:hover {
	transform: scale(1.1);
	-webkit-box-shadow: 0px 13px 15px 0px rgba(50, 50, 50, 0.75);
	-moz-box-shadow:    0px 13px 15px 0px rgba(50, 50, 50, 0.75);
	box-shadow:         0px 13px 15px 0px rgba(50, 50, 50, 0.75);
}

.App {
  background-color: white;
  height: auto;
}

.App h3 {
  padding: 20px;
}

.App svg {
  cursor: pointer;
  color: #d77eac;
}

.App svg:hover {
  color: #a73972;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(215,126,172,.5);
}

.show-modal {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: visibility 0s linear 0s,opacity .25s 0s,-webkit-transform .25s;
  transition: visibility 0s linear 0s,opacity .25s 0s,transform .25s;
  transition: visibility 0s linear 0s,opacity .25s 0s,transform .25s,-webkit-transform .25s;
}

.modal-content {
  width: 60%;
  left: 20%;
  top: 20%;
  padding: 20px;
  background-color: #a73972;
  color: white;
}

.close-button {
  cursor: pointer;
  height: 35px;
  width: 35px;
  background-color: #d77eac;
  border-radius: 50%;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 25px;
  position: absolute;
  right: 20px;
}

.Article label {
  font-weight: bold;
}

.Article input,
.Article input:focus,
.Article textarea {
  border: 2px solid #d77eac;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  outline: none;
}

.Article button:focus {
  outline: none;
}

.Article .center-single-btn {
  width: 250px;
  margin: 0 auto;
}

.Article #status {
  width: 40px;
  height: 40px;
}
